<template>
<ul class="flex-wrap-list">
  <li class="col-3" v-for="item in list" :key="item.ID">
    <a
      :href="item.url ? item.url : 'javascript:;'"
      :target="item.url ? '_blank' : ''"
      rel="noopener noreferrer"
    >
      <div class="img-box">
        <img v-lazy="event_oss_link +from+ item.img" width="100%" />
        <!-- <div class="ab-img" v-if="item.country!='other'">
        <img v-lazy="event_oss_link+'country/logo/'+item.country+'.png'" width="40" height="40">
      </div> -->
      </div>
      <p class="mt-16 text-ellipsis2">{{ item.title }}</p>
      <p class="text-sm color-grey mt-8" v-if="item.eventDate">
        {{ item.eventDate }}
        {{ item.eventEndDate ? " - " + item.eventEndDate : "" }}
      </p>
      <span class="event-btn mt-16">{{ item.eventType }}</span>
    </a>
  </li>
</ul>
</template>
<script>
export default {
  props:['list','from']
}
</script>
<style scoped>
.img-box {
  position: relative;
}

.ab-img {
  position: absolute;
  right: 12px;
  bottom: -8px;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  border: 2px solid #fff;
  overflow: hidden;
}
</style>